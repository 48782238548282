import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { ContactUsServiceService } from "../common/components/ctas/contact-us-service.service";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class MyService {

    constructor(
        private meta: Meta,
        private titleService: Title,
        @Inject(DOCUMENT) private domDocument: Document,
        private location: Location,
        private contactUsService: ContactUsServiceService,
    ) { }

    getAbsoluteUrl(): string {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const path = this.location.path();

        return `${protocol}//${host}${path}`;
    }


    openContactUsForm() {
        this.contactUsService.showPopup();
    }


    public capitalize(shorten: boolean, word: string): string {
      console.log(word + '--' + shorten)
      if (shorten)
          return word.charAt(0).toUpperCase() + word.slice(1);
      else
          return word.charAt(0).toUpperCase() + word.substring(1, word.length);
  }


    setTitleAndMeta(
        title: string,
        description: string,
        keywords: string,
        canonicalUrlEndingMatch: string
    ) {
        const canonicalUrl = this.getAbsoluteUrl();
        if (canonicalUrlEndingMatch != "NA" && canonicalUrlEndingMatch != "" && !canonicalUrl.toLowerCase().endsWith(canonicalUrlEndingMatch.toLowerCase())) {
            return;
        }

        title = this.title + " - " + title;
        description = this.description + " - " + description;
        keywords = this.keywords + ", " + keywords;

        this.titleService.setTitle(title);

        this.updateOrAddMetaTag('description', description);
        this.updateOrAddMetaTag('keywords', keywords);

        this.updateOrAddMetaTag('og:title', title, 'property');
        this.updateOrAddMetaTag('og:description', description, 'property');
        this.updateOrAddMetaTag('og:url', canonicalUrl, 'property');
        this.updateOrAddMetaTag('og:site_name', canonicalUrl, 'Genailia');

        this.updateOrAddMetaTag('twitter:title', title);
        this.updateOrAddMetaTag('twitter:site', "@genailia");
        this.updateOrAddMetaTag('twitter:description', description);
        this.updateOrAddMetaTag('twitter:url', canonicalUrl);
        this.updateOrAddMetaTag('twitter:card', "summary");

        this.setCanonicalURL(canonicalUrl);
        this.addJSONLD(canonicalUrl, title, description, "Genailia - GenZ Technologies", "https://www.genailia.com/", "https://www.genailia.com/assets/images/logo1.webp");
    }

    private addJSONLD(
        pageUrl: string,
        pageName: string,
        pageDescription: string,
        organizationName: string,
        organizationUrl: string,
        logoUrl: string
    ): void {
        const script = this.domDocument.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
    {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "${pageUrl}",
          "url": "${pageUrl}",
          "name": "${pageName}",
          "isPartOf": {
            "@id": "${organizationUrl}#website"
          },
          "datePublished": "2023-07-31T21:32:46+00:00",
          "dateModified": "2024-08-31T22:36:41+00:00",
          "description": "${pageDescription}",
          "breadcrumb": {
            "@id": "${pageUrl}#breadcrumb"
          },
          "inLanguage": "en-US",
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                "${pageUrl}"
              ]
            }
          ]
        },
        {
          "@type": "BreadcrumbList",
          "@id": "${pageUrl}#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "${organizationUrl}"
            }
          ]
        },
        {
          "@type": "WebSite",
          "@id": "${organizationUrl}#website",
          "url": "${organizationUrl}",
          "name": "${organizationName}",
          "description": "Redefining your customer and employee experience.",
          "publisher": {
            "@id": "${organizationUrl}#organization"
          },
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                "${organizationUrl}"
              ]
            }
          ],
          "inLanguage": "en-US"
        },
        {
          "@type": "Organization",
          "@id": "${organizationUrl}#organization",
          "name": "${organizationName}",
          "url": "${organizationUrl}",
          "logo": {
            "@type": "ImageObject",
            "inLanguage": "en-US",
            "@id": "${organizationUrl}#/schema/logo/image/",
            "url": "${organizationUrl}",
            "contentUrl": "${organizationUrl}",
            "width": 244,
            "height": 70,
            "caption": "${organizationName}"
          },
          "image": {
            "@id": "${organizationUrl}#/schema/logo/image/"
          },
          "sameAs": [
            "https://www.facebook.com/genzersdotai",
            "https://www.instagram.com/genzers.ai",
            "https://x.com/genailia",
            "https://youtube.com/@genailia",
            "https://www.linkedin.com/company/genailia/"
          ]
        }
      ]
    }
  `;
        this.domDocument.head.appendChild(script);
    }


    private updateOrAddMetaTag(name: string, content: string, attrName: string = 'name') {
        const existingTag = this.meta.getTag(`${attrName}="${name}"`);
        if (existingTag) {
            this.meta.removeTag(`${attrName}="${name}"`);
        }
        this.meta.addTag({ [attrName]: name, content });
    }

    private setCanonicalURL(url: string) {
        const head = this.domDocument.getElementsByTagName('head')[0];
        let existingLink: HTMLLinkElement | null = head.querySelector('link[rel="canonical"]');

        if (existingLink) {
            existingLink.href = url;
        } else {
            const link: HTMLLinkElement = this.domDocument.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', url);
            head.appendChild(link);
        }


        this.meta.addTag({
            rel: 'canonical',
            href: url,
        });
    }


    title = "Genailia - Gen AI, Linguistic AI & Accelerators";
    description = "AI/ML Models - Translation,Social Media Insights,LLM,GPT,Chatbots,Transcription,TTS,SST,ASR,Xlit";
    keywords = "translation, transcription, transliteration, languages, chatbots, GPT, NLP, LLM, AI, Social Media Insights, ML, Other Language Typing, AI Model deployments, TTS, Speech to Text, Text to Speech, ASR";



}