<div class="container py-4 associations">
  <div class="associations-heading my-4">
    <div class="associations-heading-hyphon"></div>
    <div class="associations-heading-text">OUR ASSOCIATIONS</div>
  </div>

  <div class="associations-content">
    <p><br/>
      Genailia is deeply embedded within a vibrant ecosystem of innovation and collaboration. Our journey has been bolstered by our strong partnerships with some of the most prestigious startup ecosystems and innovation platforms. These associations have not only provided us with the necessary resources and mentorship but have also enabled us to stay at the forefront of AI and technology innovation.
    </p>
    <br/><br/>
    <!-- T-Hub Association -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee1.webp" alt="T-Hub" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>T-Hub</h3>
          <p><br/>
            Genailia primarily operates out of T-Hub, one of Asia's largest startup incubators, located in Hyderabad, India. Our association with T-Hub has provided us with a dynamic coworking environment that fosters creativity and collaboration. Being part of T-Hub has allowed us to engage with other innovative startups, gain valuable insights, and access mentorship from industry experts. T-Hub's robust support system has been instrumental in shaping our strategic direction and scaling our business.
          </p>
        </div>
      </div>
    </div>

    <!-- Tech.WE and NASSCOM's 10000 Startups -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee19.webp" alt="Tech.WE" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>NASSCOM's Tech.WE and 10000 Startups</h3>
          <p><br/>
            As participants in the Tech.WE program and NASSCOM's 10000 Startups initiative, we have been part of a community that is driving India's technology and startup revolution. These platforms have provided us with exposure to industry networks, access to funding opportunities, and invaluable learning experiences. Our involvement with NASSCOM's 10000 Startups has also connected us with other like-minded entrepreneurs, fostering a spirit of collaboration and knowledge sharing.
          </p>
        </div>
      </div>
    </div>

    <!-- Microsoft for Startups -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee14.webp" alt="Microsoft for Startups" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>Microsoft for Startups</h3>
          <p><br/>
            Our participation in the Microsoft for Startups program has opened doors to world-class technology, mentorship, and a global customer base. Through this program, we have gained access to Microsoft's extensive cloud resources and technical support, which have been critical in developing and scaling our AI solutions. This partnership has accelerated our growth and enhanced our ability to deliver cutting-edge technology to our clients.
          </p>
        </div>
      </div>
    </div>

    <!-- Google for Startups -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee23.webp" alt="Google for Startups" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>Google for Startups</h3>
          <p><br/>
            Being part of the Google for Startups program has provided us with a wealth of resources, including access to Google's powerful AI tools, cloud infrastructure, and expert mentorship. This association has enabled us to leverage Google's technological advancements to refine our products and deliver exceptional solutions to our clients. The global reach and support from Google for Startups have been pivotal in our journey.
          </p>
        </div>
      </div>
    </div>

    <!-- AWS Activate and AWS Build Accelerator -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee13.webp" alt="AWS Activate" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>AWS Activate and AWS Build Accelerator</h3>
          <p><br/>
            Our inclusion in the AWS Activate program and the AWS Build Accelerator cohort has empowered us with access to AWS's robust cloud infrastructure, credits, and technical expertise. These resources have been crucial in building and deploying our scalable AI solutions. The AWS Build Accelerator, in particular, has provided us with the guidance and support needed to optimize our cloud architecture and achieve operational excellence.
          </p>
        </div>
      </div>
    </div>

    <!-- Wadhwani Foundation's Liftoff Spark 3.2 -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee12.webp" alt="Wadhwani Foundation" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>Wadhwani Foundation's Liftoff Spark 3.2</h3>
          <p><br/>
            Our participation in the Wadhwani Foundation's Liftoff Spark 3.2 program has provided us with mentorship, networking opportunities, and access to a global community of entrepreneurs. This program has been instrumental in refining our business model and go-to-market strategy, positioning us for long-term success.
          </p>
        </div>
      </div>
    </div>

    <!-- T-Hub Lab32 Cohort -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee15.webp" alt="T-Hub Lab32" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>T-Hub Lab32 Cohort 11 and 12</h3>
          <p><br/>
            Being part of T-Hub's Lab32 Cohorts 11 and 12 has been a transformative experience for Genailia. During Cohort 12, conducted in collaboration with Hexagon, a leader in digital reality solutions, we developed a real-time call translation proof of concept for emergency services using our advanced language and generative AI capabilities. This experience has validated our technological prowess and demonstrated our ability to solve real-world problems with innovative solutions.
          </p>
        </div>
      </div>
    </div>

    <!-- T-Hub Tangel Cohort -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee16.webp" alt="T-Hub Lab32" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>T-Hub T-Angel Cohort</h3>
          <p><br/>
            In addition to our other significant associations, Genailia was also selected to be part of T-Hub's TAngel cohort. TAngel is a 100-day exclusive and prestigious accelerator program designed to transform growth-stage startups into investment-ready powerhouses. Being a part of this cohort provided us with invaluable mentorship, networking opportunities, and access to industry leaders, enabling us to further refine our AI-driven solutions and position ourselves as a leader in the generative AI and Linguistic AI space. This experience has been instrumental in helping us build strong foundations and scale our offerings to meet the needs of global enterprises.
          </p>
        </div>
      </div>
    </div>

    <!-- MATH Hub (Machine Learning, Artificial Intelligence Technology Hub) -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee6.webp" alt="MATH Hub" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>MATH Hub (Machine Learning, Artificial Intelligence Technology Hub)</h3>
          <p><br/>
            Our association with the MATH Hub, sponsored by the Department of Science and Technology of India, has provided us with access to cutting-edge research and development in AI and machine learning. This partnership has enhanced our ability to innovate and stay ahead of industry trends, ensuring that our solutions are always at the forefront of technology.
          </p>
        </div>
      </div>
    </div>

    <!-- Centre for Innovation and Entrepreneurship (CIE) at IIIT-H -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee24.webp" alt="CIE at IIIT-H" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>Centre for Innovation and Entrepreneurship (CIE) at IIIT-H</h3>
          <p><br/>
            The Centre for Innovation and Entrepreneurship (CIE) at IIIT-H has been a key partner in our journey, providing us with mentorship, resources, and a platform to collaborate with some of the brightest minds in the industry. This association has been instrumental in our product development and innovation efforts, helping us bring cutting-edge solutions to market.
          </p>
        </div>
      </div>
    </div>

    <!-- DPIIT Registered with Startup India -->
    <div class="association-item">
      <div class="row">
        <div class="col-md-2">
          <img src="../../../../assets/images/marquee7.webp" alt="Startup India" class="img-fluid" />
        </div>
        <div class="col-md-10">
          <br/><h3>DPIIT Registered with Startup India</h3>
          <p><br/>
            Genailia is proud to be DPIIT registered under the Startup India program, which has provided us with access to a host of benefits, including funding opportunities, government support, and a vast network of industry leaders. This registration has been a crucial step in our journey, helping us navigate the startup ecosystem and scale the heights we aspire to reach.
    </p>
  </div>
</div>

<!-- MSME Udyam Registered and Shops and Establishments Act -->
<div class="association-item">
  <div class="row">
    <div class="col-md-2">
      <img src="../../../../assets/images/marquee17.webp" alt="MSME Udyam Registration" class="img-fluid" />
    </div>
    <div class="col-md-10">
      <br/><h3>MSME Udyam Registered and Shops and Establishments Act</h3>
      <p><br/>
        We are also MSME Udyam registered, which aligns with our commitment to growing as a recognized small to medium enterprise in India. Additionally, being registered under the Shops and Establishments Act ensures that we adhere to all legal frameworks necessary for smooth business operations. These registrations not only demonstrate our credibility but also enable us to participate in a broader range of business opportunities, grants, and collaborations, further solidifying our foundation for sustainable growth.
      </p>
    </div>  </div>
</div>
</div>
  <div class="association-summary mt-5">
    <p><br/>
      At Genailia, our network of associations with prestigious programs and institutions has been a cornerstone of our success. These relationships provide us with invaluable resources, mentorship, and industry insights, all of which contribute to our mission of advancing AI technologies. Through these collaborations, we have built a strong foundation that not only supports our current initiatives but also paves the way for future innovations.
    </p>
    <p><br/>
      We look forward to continuing our journey with these incredible partners and expanding our network to include more groundbreaking collaborations. Together, we are pushing the boundaries of what is possible with AI and setting the stage for a future where technology serves as a powerful force for positive change in the world.
    </p>
  </div>
</div>
            
