import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { QuoteService } from './quote.service';
import {
    PRODUCTS,
    SERVICE_CARDS,
    AI_SERVICES,
    FAQS,
    TAILORED_SOL,
} from 'src/app/shared/constants/home';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BlogService } from 'src/app/blog/services/blog.service';
import { AnnouncementService } from 'src/app/announcement/services/announcement.service';
import { MyService } from 'src/app/services/myservice';
declare var $: any;

interface TabContent {
    title: string;
    lead: string;
    description: string;
    details: string;
    buttonText: string;
    imageUrl: string;
}

// Define the type for the content object
interface Content {
    [key: string]: TabContent;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['../../../app.common.scss', './home.component.scss'],
})
export class HomeComponent implements OnInit {
    quote: string | undefined;
    isLoading = false;
    displayPlatformContent = false;

    serviceCards = SERVICE_CARDS;
    products = PRODUCTS;
    aiServices = AI_SERVICES;
    faqs = FAQS;
    tailored = TAILORED_SOL;
    isJqueryWorking = '';
    anchorPlacement = 'bottom-bottom';

    activeProductIndex = 0;
    activeNav = 1;
    responsiveFade = 'fade-left';
    activeGif: string | undefined = '';

    showGif(gif: string, index: number) {
        this.activeProductIndex = index;
        const videoElement = document.getElementById('productVideo') as HTMLVideoElement;
        const sourceElement = document.getElementById('productVideoSource') as HTMLSourceElement;
        sourceElement.src = `${gif}`;
        videoElement.load();
    }

    @ViewChild('typeTarget', { static: true }) typeTarget!: ElementRef;

    constructor(
        deviceService: DeviceDetectorService,
        private blogService: BlogService,
        public myservice: MyService,
        private announcementService: AnnouncementService
    ) {
        if (deviceService.isMobile()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
        if (deviceService.isDesktop()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }

    }

    navigateToSignup() {

        window.open('https://app.genailia.com/dashboard', '_blank');

    }
    relatedBlogs: any[] = [];
    annoucements: any[] = [];


    getRelatedBlogs() {
        this.blogService.getBlogPosts(undefined, false, undefined, 3, "random").subscribe(res => {
            console.log(res);
            this.relatedBlogs = res;
        })

    }

    getAnnoucements() {
        this.announcementService.getBlogPosts(undefined, false, undefined, undefined, "random").subscribe(res => {
            this.annoucements = res;
            console.log(res);
        })
    }

    logandsignup(email: string) {
        window.open('https://app.genailia.com', '_blank');
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }
    public mobile = false;
    ngOnInit() {
        this.isLoading = true;
        $(document).ready(() => {
            this.isJqueryWorking = 'Welcome To Genailia.com';
            console.log(this.isJqueryWorking);
        });


        if (window.screen.width >= 767) { // 768px portrait
            this.mobile = true;
        }
        else {
            this.mobile = false;
        }

        this.getRelatedBlogs();
        this.getAnnoucements();
        this.myservice.setTitleAndMeta("Home","Home of Conversational AI, Linguistic Interfaces, Search AI, Experience Accelerators and many other latest AI Technologies, Products, Services, and Solutions", "Conversational AI, Search AI, RAG Knowledge Graphs, Multilingual, Multimodal, Voice Enabled AI Models and chatbots", "NA");
        
    }




    content: Content = {
        'experience-ai': {
            title: 'Experience AI',
            lead: 'Enhance customer, agent, and employee experiences.',
            description: 'Experience AI integrates with existing systems to provide personalized interactions and automate routine tasks, improving efficiency and satisfaction.',
            details: 'Our solutions are designed to make interactions smoother and more meaningful, whether it\'s through custom chatbots, personalized content, or automated workflows.',
            buttonText: 'Explore More',
            imageUrl: '../../../../assets/images/experience-ai.webp'
        },
        'search-ai': {
            title: 'Search AI',
            lead: 'Unlock the power of data with intelligent search capabilities.',
            description: 'Search AI enables businesses to quickly find and retrieve information across vast datasets, improving efficiency and decision-making.',
            details: 'Whether it\'s internal knowledge bases or customer-facing search features, our Search AI solutions provide accurate, context-aware results that meet your specific needs.',
            buttonText: 'Discover More',
            imageUrl: '../../../../assets/images/search-ai.webp'
        },
        'conversational-ai': {
            title: 'Generative AI',
            lead: 'Engage with customers in real-time across channels',
            description: 'Our Conversational AI products provide seamless interactions with customers, powered by cutting-edge language models that understand and respond in a human-like manner.',
            details: 'These solutions are designed to enhance customer engagement, reduce response times, and improve overall satisfaction by offering 24/7 support.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/conversational-ai.webp'
        },
        'multilingual-ai': {
            title: 'Multilingual AI',
            lead: 'Break language barriers and reach a global audience.',
            description: 'Multilingual AI supports over 150 languages, offering translation, transcription, and text-to-speech services that cater to diverse audiences.',
            details: 'These tools ensure that your content is accessible to everyone, regardless of the language they speak, enhancing your global reach and engagement.',
            buttonText: 'See Details',
            imageUrl: '../../../../assets/images/multilingual-ai.webp'
        },

    };

    selectedTab: string = 'experience-ai';
    selectTab(tab: string) {
        this.selectedTab = tab;
    }

    getContent(tab: string): TabContent {
        return this.content[tab];
    }



    formatToSlugWithGuid(input: string, guid: string): string {

        const slug = input
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .trim()
            .replace(/\s+/g, '-');

        return `${slug}-${guid}`;
    }


    tablecards = [1, 2, 3]
cards = [
    {
        iconClass: 'service-box_icon fa-solid fa-lock add2',
        title: 'Advanced Security & SSO',
        description: 'Secure with encryption (transit & at-rest), RBAC, MFA, SSO, OAuth2, JWT. SOC 2/ISO 27001 compliant, Zero Trust architecture.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-cloud add2',
        title: 'Scalable Infrastructure',
        description: 'Horizontally scalable with microservices, Kubernetes, Docker, auto-scaling, cloud-native, and multi-cloud compatibility.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-bolt add2',
        title: 'High Performance',
        description: 'Low-latency APIs, async & parallel processing, WebSocket support, and rate-limiting ensure optimal performance.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-check-circle add2',
        title: 'Compliance & Governance',
        description: 'Compliant with GDPR, HIPAA, offering audit logs & reporting, encryption, data retention, and API governance.',
    },
];

    tagCards = ['Model Hub', 'Prompt Studio', 'AI Agents', 'RLHF | RLAIF Evaluation Studio']

    logosrandom: string[] = [
        'assets/images/marquee11.webp',
        'assets/images/marquee12.webp',
        'assets/images/marquee8.webp',
        'assets/images/marquee13.webp',
        'assets/images/marquee6.webp',
        'assets/images/marquee14.webp',
        'assets/images/marquee24.webp',
        'assets/images/marquee7.webp',
        'assets/images/marquee19.webp',
        'assets/images/marquee17.webp',
        'assets/images/marquee23.webp',
        'assets/images/marquee15.webp',
        'assets/images/marquee16.webp',


        // Add more logos here
    ];
    logos: string[] = [
        'assets/images/marquee6.webp',
        'assets/images/marquee7.webp',
        'assets/images/marquee8.webp',
        'assets/images/marquee11.webp',
        'assets/images/marquee12.webp',
        'assets/images/marquee13.webp',
        'assets/images/marquee14.webp',
        'assets/images/marquee15.webp',
        'assets/images/marquee16.webp',
        'assets/images/marquee17.webp',
        'assets/images/marquee19.webp',
        'assets/images/marquee23.webp',
        'assets/images/marquee24.webp',


        // Add more logos here
    ];
    logos2: string[] = [
        'assets/images/28.webp',
        'assets/images/29.webp',
        'assets/images/30.webp',
        'assets/images/31.webp',
        'assets/images/25.webp',
        'assets/images/26.webp',
        'assets/images/27.webp'
    ];

    logos3: string[] = [
        'assets/images/5.webp',
        'assets/images/20.webp',
        'assets/images/3.webp',
        'assets/images/22.webp',
        'assets/images/21.webp',
    ];




    get reversedLogos(): string[] {
        return [...this.logosrandom];
    }

    get reversedLogos2(): string[] {
        return [...this.logos3].reverse(); // Create a new reversed array
    }

    HeroTags: any[] = [
        'TTS',
        'Translation',
        'Transcription',
        'Transliteration',
        'OCR',
        '100 Languages',
        '2500+ TTS Voices',
        'Voice Cloning',
        'Language over Sockets',
        'Text and Spoken Language Detection',
        'Social Media Insights'
    ]
    HeroTagsNew: any = [
        'Generative AI',
        'Private LLM',
        'Multi-lingual',
        'Multi-Modal', 
        'Voice-enabled',
        'Chatbots',
        'Images Generation',
        'Image Explanation'
    ]
    HeroTagsNew2: any = [
        'LangChain',
        'LLaMaIndex',
        'Knowledge Graphs',
        'Retrieval-Augmented Generation (RAG)',
        'Vector DB',
        'Natural Language Processing (NLP)',
    ]

    isFlipped: boolean[] = [];

    onMouseEnter(index: number) {
        this.isFlipped[index] = true;
    }

    onMoteuseLeave(index: number) {
        this.isFlipped[index] = false;
    }
    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }
    tags = [
        {
            name: 'BankAssist'
        },
        {
            name: 'RetailAssist'
        },
        {
            name: 'HealthAssist'
        }
    ];

}
