<div class="container-max-width">


	<section class="section-custom-layout">
		<div class="section-custom-content">
			<div class="section-custom-text" data-aos="fade-right">
				<h2>
					<b>eLLMo</b> - Empowering AI Search and Generative Insights in
					Enterprises
				</h2>
				<h1>
					eLLMo, short for Enterprise Large Language Model Outcomes, is your
					<b>Private Large Language Model (LLM)</b>. eLLMo redefines
					information access with advanced AI capabilities. Leveraging <b>LLM-based
						Question and Answer (Q&A) models</b> and a powerful Platform as a
					Service (PaaS) API with <b>LLM vector search</b>, eLLMo excels in
					generative AI search. It allows fine-tuning for specific needs,
					making it the go-to solution for the best in large language model
					AI.<br /> In the realm of <b>AI for operations and enterprise</b>,
					eLLMo stands out, offering versatile applications in the workplace.
					Its adaptability extends to language-focused AI, supporting GPT in
					various languages, including native language applications.
				</h1>
			</div>
		</div>

		<div class="image-container" data-aos="fade-left">
			<img loading="lazy" src="../../assets/images/home4.webp"
				alt="Your Image Alt Text" />
		</div>
	</section>

</div>

<div class="container" data-aos="fade-up">
	<!-- Dynamic Tabs -->
	<div class="row mb-2">
		<div *ngFor="let key of getKeys(content); let i = index"
			data-aos="fade-up" [attr.data-aos-delay]="80 * i"
			class="col-md-2 col-5 text-center rounding-new mb-2"
			style="border: 1px solid #071359"
			[ngClass]="{'bg-custom': selectedTab === key}"
			(click)="selectTab(key)">
			<span class="py-3 mb-0">{{ content[key].title }}</span>
		</div>
	</div>
	<!-- Dynamic Tab Content -->
	<div class="tab-content" id="myTabContent">
		<div *ngFor="let key of getKeys(content)" class="tab-pane fade"
			[ngClass]="{'show active': selectedTab === key}"
			[class.d-none]="selectedTab !== key">
			<div class="row">
				<div class="col-md-6">
					<h3 class="mb-4">{{ getContent(key).lead }}</h3>
					<!-- <p class="lead">{{ getContent(key).lead }}</p> -->
					<p>{{ getContent(key).description }}</p>
					<p>{{ getContent(key).details }}</p>
					<!-- 
                        <a href="#" class="btn btn-primary mt-4">{{
                            getContent(key).buttonText }}</a> -->
				</div>

				<div class="col-md-6 text-center">
					<div class="image-container">
						<img [src]="getContent(key).imageUrl"
							class="img-fluid image-round" alt="{{ getContent(key).title }}">
					</div>
				</div>

			</div>
		</div>
	</div>
</div>



<div class="container-full-width">
	<h5 class="heading-custom" data-aos="fade-up">
		eLLMo <span>Features</span>
	</h5>
	<div class="grid-container">
		<div *ngFor="let card of features; let index = index"
			class="card-container" data-aos="fade-up"
			[attr.data-aos-delay]="100 * index" data-aos-offset="50">
			<div>
				<img [src]="'assets/images/ellmofeature' + (index + 1) + '.webp'"
					alt="{{card.title}} {{getWordsByIndex(index+1)}}" />
			</div>
			<div class="card-content">
				<h3>{{ card.title }}</h3>
				<p>{{ card.description }}</p>
			</div>
		</div>
	</div>
</div>


<section class="section-container">
	<div class="section-bg">
		<h5 class="heading-custom" data-aos="fade-up" data-aos-offset="80">
			Diverse Compatibility with <span>Various File Formats</span>
		</h5>

		<p class="text-content" data-aos="fade-up" data-aos-offset="70">
			eLLMo, your advanced <b>PrivateGPT/PrivateLLM</b> solution, excels in
			<b>AI-powered searches</b>, handling diverse file formats including <b>TXT,
				DOC, DOCX, PPT, PPTX, PDF, HTML, MD, JSON</b>, and extracting
			transcripts from speech, video, and audio. With <b>LLM-based Q&A</b>
			and PaaS AI features, generative AI search capabilities. It supports
			small language models, <b>LLM vector search</b>, and entity
			recognition, making it versatile for various contexts. For top-tier
			large language model AI, eLLMo provides a comprehensive overview,
			supporting <b>private GPT and LLMs</b>, allowing organizations to
			train and fine-tune their models. In <b>AI for operations</b>,
			enterprise, and language applications.
		</p>

		<img width="140" height="140" src="../../assets/images/ellmoff1.webp"
			class="image-overlay now top-5 left-5 md-visible" /> <img
			width="140" height="140" src="../../assets/images/ellmoff2.webp"
			class="image-overlay now bottom-20 left-1 md-visible" /> <img
			width="140" height="140" src="../../assets/images/ellmoff3.webp"
			class="image-overlay now bottom-5 left-15 md-visible" /> <img
			width="140" height="140" src="../../assets/images/ellmoff4.webp"
			class="image-overlay now top-5 right-5 md-visible" /> <img
			width="140" height="140" src="../../assets/images/ellmoff5.webp"
			class="image-overlay now bottom-20 right-1 md-visible" /> <img
			width="140" height="140" src="../../assets/images/ellmoff6.webp"
			class="image-overlay now bottom-5 right-15 md-visible" />
	</div>
</section>



<section class="section_container">
	<h5 class="header_title" data-aos="fade-up">
		Generative AI-powered workplace <span> <br /> Search and
			Q&amp;A
		</span>
	</h5>

	<div class="flex_container md_flex_row">
		<div class="image_card" data-aos="fade-left">
			<img loading="lazy" src="../../assets/images/main.webp"
				alt="Your Image Alt Text" />
		</div>

		<div class="text_container">
			<div class="flex_container md_flex_row" data-aos="fade-right">
				<ul>
					<li>
						<h6>
							<b>Advanced AI-Powered Search </b>eLLMo offers a sophisticated
							search experience, leveraging AI to navigate and retrieve
							information seamlessly.
						</h6>
					</li>
					<li>
						<h6>Private LLM that is deployed on your infrastructure -
							on-prem or on private cloud. Deploy to any Public cloud provider
							of your choice - AWS/Azure/GCP/E2E/Others!</h6>
					</li>
					<li>
						<h6>
							<b>LLM-Based Q&A Model</b> With a powerful <b>LLM-based
								Question and Answer model</b>, eLLMo enhances your ability to
							interact intelligently with data.
						</h6>
					</li>
					<li>
						<h6>
							<b>LLM-Based PaaS AI Integration</b> eLLMo serves as a dynamic
							Platform as a Service (PaaS) for AI, incorporating LLM-based
							capabilities for versatile applications.
						</h6>
					</li>
					<li>
						<h6>
							Also available as <a class="underline" href="/app"
								target="_blank">PaaS model</a>
						</h6>
					</li>
					<li>
						<h6>AI grounded in your company’s own trusted knowledge
							model unlocks workplace product.</h6>
					</li>
				</ul>
			</div>
		</div>
	</div>




</section>


