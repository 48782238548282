<header>
	<nav
		class="navbar navbar-horizontal navbar-expand-md navbar-fixed fixed-top bg-white border-bottom">

		<div class="container-fluid d-flex justify-content-between ">
			<a routerLink="/home"> <img *ngIf="special" alt="genailia.com"
				width="auto" height="54" class="cursor-pointer add"
				src="assets/images/special.webp" /> <img *ngIf="shabdai"
				alt="shabdai" width="auto" height="60" class="cursor-pointer add"
				src="assets/images/shabdai.webp" /> <img
				*ngIf="!special && !shabdai" alt="genailia.com" width="auto"
				height="41" class="cursor-pointer add"
				src="assets/images/logo1.webp" />

			</a>
			<button *ngIf="menuHidden" type="button" aria-controls="navbar-menu"
				aria-label="Toggle navigation" (click)="menuHidden = false"
				class="btn btn-neutral btn-icon d-md-none"
				[attr.aria-expanded]="!menuHidden">
				<i class="fa-solid fa-bars"></i>
			</button>

			<div id="navbar-menu" class="collapse navbar-collapse float-xs-none"
				[ngbCollapse]="menuHidden">
				<div class="text-right">
					<button *ngIf="!menuHidden" type="button"
						aria-controls="navbar-menu" aria-label="Toggle navigation"
						(click)="menuHidden = true"
						class="btn btn-neutral text-danger btn-icon"
						[attr.aria-expanded]="menuHidden">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>

				<ul class="navbar-nav ml-md-auto position-relative">
					<li *ngIf="!special" class="nav-item"><a (click)="menuHidden = true"
						
						class="nav-link navbar-txt" routerLink="/home">Home <span
							class="sr-only">(current)</span></a></li>

					<li *ngIf="!special" class="nav-item dropdown" ngbDropdown
						#dropdownfree="ngbDropdown"
						(mouseenter)="openDropdown(dropdownfree)"
						(mouseleave)="closeDropdown(dropdownfree)"><a
						class="nav-link navbar-txt new-diff" href="javascript:void(0)"
						ngbDropdownToggle>Genailia <span class="sr-only">(current)</span>
					</a>

						<div ngbDropdownMenu class="p-2">
							<a (click)="menuHidden = true" ngbDropdownItem
								*ngFor="let product of genproducts;let index = index"
								[href]="genproducts[index].link">

								<div class="drop_card-body">
									<!-- Left Side Icon -->
									<div class="drop_icon-container add2">
										<i [class]="product.icon"></i>
										<!-- Replace with your desired icon class -->
									</div>
									<!-- Right Side Title -->
									<div class="drop_text-container">
										<h5 class="drop_card-title">{{product.title}}</h5>
									</div>
								</div>

							</a>

						</div></li>


			
	


					<li *ngIf="!special" class="nav-item dropdown" ngbDropdown
						#dropdownfree="ngbDropdown"
						(mouseenter)="openDropdown(dropdownfree)"
						(mouseleave)="closeDropdown(dropdownfree)"
						(click)="takeittoresources('/aiproducts')"><a
						class="nav-link navbar-txt new-diff" href="javascript:void(0)"
						ngbDropdownToggle>Free Tools <span class="sr-only">(current)</span>
					</a>

						<div ngbDropdownMenu class="p-2">
							<a (click)="menuHidden = true" ngbDropdownItem
								*ngFor="let product of products;let index = index"
								[href]="products[index].link">

								<div class="drop_card-body">
									<!-- Left Side Icon -->
									<div class="drop_icon-container add2">
										<i [class]="product.icon"></i>
										<!-- Replace with your desired icon class -->
									</div>
									<!-- Right Side Title -->
									<div class="drop_text-container">
										<h5 class="drop_card-title">{{product.title}}</h5>
									</div>
								</div>

							</a>

						</div></li>


						<li class="nav-item dropdown my-auto different_many" ngbDropdown>
							<a class="nav-link navbar-txt" href="javascript:void(0)" ngbDropdownToggle>Resources
								<span class="sr-only">(current)</span>
						</a>
							<div ngbDropdownMenu class="p-2">
								<a ngbDropdownItem
									*ngFor="let product of items;let index = index"
									[href]="resources[index].link"
									[attr.target]="isGenerativeAI(resources[index]) ? '_blank' : null">
	
									<div class="drop_card-body">
										<!-- Left Side Icon -->
										<div class="drop_icon-container add2">
											<i [class]="product.icon"></i>
											<!-- Replace with your desired icon class -->
										</div>
										<!-- Right Side Title -->
										<div class="drop_text-container">
											<h5 class="drop_card-title">{{product.title}}</h5>
										</div>
									</div>
	
								</a>
	
							</div>
						</li>


					<li class="nav-item dropdown different" ngbDropdown #dropdown="ngbDropdown"><a
						class="nav-link navbar-txt new-diff" href="javascript:void(0)"
						ngbDropdownToggle (mouseenter)="openDropdown(dropdown)"
						(mouseleave)="closeDropdown(dropdown)">Resources <span
							class="sr-only">(current)</span>
					</a>
						<div ngbDropdownMenu class="dropdown-menuy"
							(mouseenter)="openDropdown(dropdown)"
							(mouseleave)="closeDropdown(dropdown)">
							<div class="container mt-4">
								<div class="row">
									<div class="col-md-5">
										<div class="drop_card-container">
											<a (click)="menuHidden = true" class="drop_card"
												*ngFor="let item of items;let index = index"
												[href]="resources[index].link"
												[attr.target]="isGenerativeAI(resources[index]) ? '_blank' : null">
												<div class="drop_card-body">
													<!-- Left Side Icon -->
													<div class="drop_icon-container add2">
														<i [class]="item.icon"></i>
														<!-- Replace with your desired icon class -->
													</div>
													<!-- Right Side Title -->
													<div class="drop_text-container">
														<h5 class="drop_card-title">{{item.title}}</h5>
													</div>
												</div>
											</a>
										</div>


									</div>

									<div class="col-md-7">
										<div class="card">
											<div class="card-body">
												<h5 class="card-title">Latest Blogs</h5>
												<div class="latest-blogs">
													<ul class="list-unstyled">
														<li *ngFor="let item of headerBlogs">
															<div class="blog-card">
																<img [src]="item.imageUrl" alt="Post Image">
																<div class="blog-card-body">
																	<h5 class="blog-title">{{item.title}}</h5>
																	<p class="blog-text">{{item.pubDate}}</p>

																	<a (click)="menuHidden = true"
																		[routerLink]="['blog/post', formatToSlugWithGuid(item.title,item.guid)]"
																		class="read-more tw-cursor-pointer">Read More</a>
																</div>
															</div>

														</li>




													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div></li>
					<li *ngIf="!special" class="nav-item"><a (click)="menuHidden = true"
						class="nav-link navbar-txt" routerLink="/pricing">Pricing <span
							class="sr-only">(current)</span></a></li>

					<!-- <li class="nav-item">
            <a (click)="menuHidden = true" class="nav-link" routerLink="/contact"
              >Contact <span class="sr-only">(current)</span></a
            >
          </li> -->



					<li *ngIf="!special" class="nav-item dropdown different" ngbDropdown
						#dropdowncompany="ngbDropdown"
						(mouseenter)="openDropdown(dropdowncompany)"
						(mouseleave)="closeDropdown(dropdowncompany)"><a
						class="nav-link navbar-txt new-diff" href="javascript:void(0)"
						ngbDropdownToggle>Company <span class="sr-only">(current)</span>
					</a> <!-- <div ngbDropdownMenu>
							<a (click)="menuHidden = true" ngbDropdownItem *ngFor="let product of company"
								[href]="product.link"
								[attr.target]="isGenerativeAI(product) ? '_blank' : null">
								{{ product.title }}</a>
						</div> -->

						<div ngbDropdownMenu class="p-2">
							<a (click)="menuHidden = true" ngbDropdownItem
								*ngFor="let product of items2;let index = index"
								[href]="company[index].link"
								[attr.target]="isGenerativeAI(company[index]) ? '_blank' : null">

								<div class="drop_card-body">
									<!-- Left Side Icon -->
									<div class="drop_icon-container add2">
										<i [class]="product.icon"></i>
										<!-- Replace with your desired icon class -->
									</div>
									<!-- Right Side Title -->
									<div class="drop_text-container">
										<h5 class="drop_card-title">{{product.title}}</h5>
									</div>
								</div>

							</a>

						</div></li>
						

						<li *ngIf="!special" class="nav-item dropdown different_many" ngbDropdown><a
							class="nav-link navbar-txt" href="javascript:void(0)"
							ngbDropdownToggle>Company <span class="sr-only">(current)</span>
						</a>
							<div ngbDropdownMenu>
								<a (click)="menuHidden = true" ngbDropdownItem
								*ngFor="let product of items2;let index = index"
								[href]="company[index].link"
								[attr.target]="isGenerativeAI(company[index]) ? '_blank' : null">

								<div class="drop_card-body">
									<!-- Left Side Icon -->
									<div class="drop_icon-container add2">
										<i [class]="product.icon"></i>
										<!-- Replace with your desired icon class -->
									</div>
									<!-- Right Side Title -->
									<div class="drop_text-container">
										<h5 class="drop_card-title">{{product.title}}</h5>
									</div>
								</div>

							</a>
							</div></li>





					<!--           <li   *ngIf="!special" class="nav-item dropdown" ngbDropdown>
            <a (click)="menuHidden = true" class="nav-link" href="javascript:void(0)" ngbDropdownToggle
              >Resources <span class="sr-only">(current)</span></a
            >
            <div ngbDropdownMenu>
              <a (click)="menuHidden = true"
                ngbDropdownItem
                [routerLink]="resource.url"
                *ngFor="let resource of resources"
                >{{ resource.title }}</a>
            </div>
          </li> -->

					<!-- <li class="nav-item dropdown" ngbDropdown>
            <a (click)="menuHidden = true" class="nav-link" href="javascript:void(0)" ngbDropdownToggle
              >Members <span class="sr-only">(current)</span></a
            >
            <div ngbDropdownMenu>
              <a (click)="menuHidden = true"
                ngbDropdownItem
                [routerLink]="member.url"
                *ngFor="let member of members"
                >{{ member.title }}</a
              >
            </div>
          </li> -->

					<!--  <li class="nav-item" ngbDropdown>
            <a (click)="menuHidden = true"
              class="nav-link"
              tabindex="0"
              ngbDropdownToggle
              id="navbarDropdown2"
              role="button"
              ><i class="genzItm"></i> <span *ngIf="!userData">Members</span>
              <span *ngIf="userData">
                {{ userData.firstname }}
              </span>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown1"
              class="dropdown-menu genzItm"
              *ngIf="!isLoggedIn"
            >
              <a (click)="menuHidden = true" ngbDropdownItem routerLink="/login"
                ><i class="genzItm"></i> Login</a
              >
              <a (click)="menuHidden = true" ngbDropdownItem routerLink="/signup"
                ><i class="genzItm"></i> Sign Up</a
              >
            </div>
            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown1"
              class="dropdown-menu genzItm"
              *ngIf="isLoggedIn"
            >
              <a (click)="menuHidden = true" ngbDropdownItem routerLink="/user/profile"
                ><i class="genzItm"></i>

                Profile</a
              >
              <hr class="dropdown-divider" />
              <a (click)="menuHidden = true" ngbDropdownItem class="logout" (click)="logout()"
                ><i class="genzItm"></i> Logout</a
              >
            </div>
          </li> -->
					<!-- openContactUsForm() -->
					<!-- Contacu US -->
					<!-- <li class="nav-item d-md-block ml-md-4 my-auto">
            <a (click)="menuHidden = true" routerLink="/contact" class="btn btn-warning">
              <span class="nav-link-inner--text">Contact Us</span>
            </a>
          </li> -->

					<li class="nav-item d-md-block ml-md-4 my-auto">
						<!-- (click)="openContactUsForm()" --> <!-- <a (click)="menuHidden = true" routerLink="/contact"
						class="btn btn-warning"> <span class="nav-link-inner--text">Book a
								demo</span>
					</a> --> <a (click)="menuHidden = true" href=""
						onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;"
						class="btn btn-warning"> <span class="nav-link-inner--text">Book
								a demo</span>
					</a>
					</li>

					<li class="nav-item d-md-block ml-md-4 my-auto"><a (click)="menuHidden = true"
						href="https://app.genailia.com"
						class="btn-dark-navy btn btn-default" target="blank"> <span
							class="nav-link-inner--text">Try now !</span> <span
							*ngIf="!isBackendUp" class="status blink-text">Backend
								DOWN</span> <span *ngIf="isBackendUp"
							class="status status-up blink-text">Backend UP</span>
					</a></li>

				</ul>
			</div>
		</div>



	</nav>



</header>
